import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import mattressIpsum from 'mattress-ipsum';
import { Tabs, PanelItem, Panel } from '../../src';
import tabData, { customTabHeaders, ipsumArray, tabItems } from './helpers/TabData.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tabs"
    }}>{`Tabs`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { Tabs } from 'mfcl'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Tabs allow you to switch between different views of related information.`}</p>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={Tabs} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<Tabs name=\"default\" items={tabData} defaultValue={2} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tabs,
      PanelItem,
      Panel,
      tabData,
      customTabHeaders,
      ipsumArray,
      tabItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Tabs name="default" items={tabData} defaultValue={2} mdxType="Tabs" />
    </Playground>
    <h2 {...{
      "id": "controlled-by-state"
    }}>{`Controlled by state`}</h2>
    <Playground __position={2} __code={'<Component initialState={{ activeTab: 0 }}>\n  {({ setState, state }) => (\n    <>\n      <Tabs\n        name=\"controlled\"\n        onChange={activeTab => setState({ activeTab })}\n        value={state.activeTab}\n        items={tabData}\n      />\n      <Panel>\n        {ipsumArray.map((item, index) => (\n          <PanelItem\n            name=\"controlled\"\n            isSelected={index === state.activeTab}\n            index={index}\n            key={index}\n          >\n            {item}\n          </PanelItem>\n        ))}\n      </Panel>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tabs,
      PanelItem,
      Panel,
      tabData,
      customTabHeaders,
      ipsumArray,
      tabItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        activeTab: 0
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Tabs name="controlled" onChange={activeTab => setState({
            activeTab
          })} value={state.activeTab} items={tabData} mdxType="Tabs" />
        <Panel mdxType="Panel">
          {ipsumArray.map((item, index) => <PanelItem name="controlled" isSelected={index === state.activeTab} index={index} key={index} mdxType="PanelItem">
              {item}
            </PanelItem>)}
        </Panel>
      </>}
  </Component>
    </Playground>
    <h2 {...{
      "id": "custom-tabs"
    }}>{`Custom Tabs`}</h2>
    <Playground __position={3} __code={'<Component initialState={{ activeTab: 0 }}>\n  {({ setState, state }) => {\n    return (\n      <>\n        <Tabs\n          name=\"custom\"\n          onChange={activeTab => setState({ activeTab })}\n          value={state.activeTab}\n          items={customTabHeaders}\n        />\n        <PanelItem name=\"custom\" isSelected={0 === state.activeTab} index={0}>\n          {mattressIpsum(1)}\n        </PanelItem>\n        <PanelItem name=\"custom\" isSelected={1 === state.activeTab} index={1}>\n          {mattressIpsum(1)}\n        </PanelItem>\n        <PanelItem name=\"custom\" isSelected={2 === state.activeTab} index={2}>\n          {mattressIpsum(1)}\n        </PanelItem>\n        <PanelItem name=\"custom\" isSelected={3 === state.activeTab} index={3}>\n          {mattressIpsum(1)}\n        </PanelItem>\n      </>\n    )\n  }}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tabs,
      PanelItem,
      Panel,
      tabData,
      customTabHeaders,
      ipsumArray,
      tabItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        activeTab: 0
      }} mdxType="Component">
    {({
          setState,
          state
        }) => {
          return <>
          <Tabs name="custom" onChange={activeTab => setState({
              activeTab
            })} value={state.activeTab} items={customTabHeaders} mdxType="Tabs" />
          <PanelItem name="custom" isSelected={0 === state.activeTab} index={0} mdxType="PanelItem">
            {mattressIpsum(1)}
          </PanelItem>
          <PanelItem name="custom" isSelected={1 === state.activeTab} index={1} mdxType="PanelItem">
            {mattressIpsum(1)}
          </PanelItem>
          <PanelItem name="custom" isSelected={2 === state.activeTab} index={2} mdxType="PanelItem">
            {mattressIpsum(1)}
          </PanelItem>
          <PanelItem name="custom" isSelected={3 === state.activeTab} index={3} mdxType="PanelItem">
            {mattressIpsum(1)}
          </PanelItem>
        </>;
        }}
  </Component>
    </Playground>
    <h2 {...{
      "id": "styled-tabs"
    }}>{`Styled Tabs`}</h2>
    <Playground __position={4} __code={'<Component initialState={{ activeTab: 0 }}>\n  {({ setState, state }) => (\n    <>\n      <Tabs\n        name=\"controlled\"\n        variant=\"secondary\"\n        onChange={activeTab => setState({ activeTab })}\n        value={state.activeTab}\n        items={tabData}\n      />\n      <Panel>\n        {ipsumArray.map((item, index) => (\n          <PanelItem\n            name=\"controlled\"\n            isSelected={index === state.activeTab}\n            index={index}\n            key={index}\n          >\n            {item}\n          </PanelItem>\n        ))}\n      </Panel>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tabs,
      PanelItem,
      Panel,
      tabData,
      customTabHeaders,
      ipsumArray,
      tabItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        activeTab: 0
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Tabs name="controlled" variant="secondary" onChange={activeTab => setState({
            activeTab
          })} value={state.activeTab} items={tabData} mdxType="Tabs" />
        <Panel mdxType="Panel">
          {ipsumArray.map((item, index) => <PanelItem name="controlled" isSelected={index === state.activeTab} index={index} key={index} mdxType="PanelItem">
              {item}
            </PanelItem>)}
        </Panel>
      </>}
  </Component>
    </Playground>
    <h2 {...{
      "id": "disabled-tabs"
    }}>{`Disabled Tabs`}</h2>
    <Playground __position={5} __code={'<Component initialState={{ activeTab: 0 }}>\n  {({ setState, state }) => (\n    <>\n      <Tabs\n        name=\"controlled\"\n        variant=\"secondary\"\n        onChange={activeTab => setState({ activeTab })}\n        value={state.activeTab}\n        items={tabItems}\n      />\n      <Panel>\n        {ipsumArray.map((item, index) => (\n          <PanelItem\n            name=\"controlled\"\n            isSelected={index === state.activeTab}\n            index={index}\n            key={index}\n          >\n            {item}\n          </PanelItem>\n        ))}\n      </Panel>\n    </>\n  )}\n</Component>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      mattressIpsum,
      Tabs,
      PanelItem,
      Panel,
      tabData,
      customTabHeaders,
      ipsumArray,
      tabItems,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Component initialState={{
        activeTab: 0
      }} mdxType="Component">
    {({
          setState,
          state
        }) => <>
        <Tabs name="controlled" variant="secondary" onChange={activeTab => setState({
            activeTab
          })} value={state.activeTab} items={tabItems} mdxType="Tabs" />
        <Panel mdxType="Panel">
          {ipsumArray.map((item, index) => <PanelItem name="controlled" isSelected={index === state.activeTab} index={index} key={index} mdxType="PanelItem">
              {item}
            </PanelItem>)}
        </Panel>
      </>}
  </Component>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      